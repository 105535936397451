.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

p#free {
  color: #c9ff03;
}

p#parking_free {
  color: #c9ff03;
}

p#parking_complete {
  color: #e4232a;
}

div#biblioteca {
  margin: auto;
  color:white;
}

*.App-header {
  height: 20vh;
  background-color: #12273A;
  display: flex;
  justify-content: center;
}

.App-body {
  background-color: #182F4e;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 5vmin);
  font-weight: 600;
  color: white;
}

.App-link {
  color: #61dafb;
}

div#iconR {
  left: 27%;
}


div#iconC {
  left: 35%;
}

img#quartAjuntament {
  height:100%
}

img#parkingbliblioteca {
  height:100%;
}

#center{
  width: 20%;
}

.column{
  scale: 1.5;
}